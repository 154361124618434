.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  font-family: Outfit, sans-serif !important;
}

/* elements */
button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.headingRedUnderline {
  width: 15%;
  border-bottom: 2px solid red;
}

/* custom css */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 100px;
  box-sizing: border-box;
  color: #000;
  background-color: rgb(244 255 244);
  /* box-shadow: 2px 13px 20px -20px rgba(0, 0, 0, 0.45); */
  /* box-shadow: 0px -61px 136px -9px rgba(0, 0, 0, 0.45); */
  position: sticky;
  top: 0px;
  z-index: 1000;
  height: 14vh;
}

.navbar_scrollable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 100px;
  box-sizing: border-box;
  color: #000;
  background-color: rgb(244 255 244);
  /* box-shadow: 2px 13px 20px -20px rgba(0, 0, 0, 0.45); */
  /* box-shadow: 0px -61px 136px -9px rgba(0, 0, 0, 0.45); */
  /* position: sticky; */
  /* top: 0px; */
  z-index: 1000;
  height: 14vh;
}

.navbar_left {
  display: flex;
  align-items: center;
}

.navbar_logo_img {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

.navbar_logo_parent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar_logo_parent>div {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}

.navbar_logo_name {
  font-size: 25px;
  font-weight: bold;
}

.navbar_right {
  display: flex;
  align-items: center;
}

.navbar_navigation_items {
  display: flex;
  align-items: center;
  gap: 40px;
}

.navbar_login_button {
  /* border: 1px solid black;
  padding: 8px;
  border-radius: 4px;
  width: 90px; */
  /* background: linear-gradient(
    90deg,
    rgba(19, 203, 12, 1) 24%,
    rgba(103, 212, 110, 1) 49%
  ); */
  background-color: transparent;

  border: 2px solid black;
  padding: 4px 8px;
  border-radius: 6px;
  width: max-content;
  color: black;
  /* background: linear-gradient(
    90deg,
    rgba(19, 203, 12, 1) 24%,
    rgba(103, 212, 110, 1) 49%
  ); */
  background-color: #00e785;
  font-weight: bold;
}

.navbar_login_button:hover {
  /* background: linear-gradient(
    90deg,
    rgba(19, 203, 12, 1) 24%,
    rgba(103, 212, 110, 1) 49%
  ); */
  /* background-color: #00e785;
  color: white;
  border: none; */
}

.navbar_navigation_item {
  cursor: pointer;
  font-weight: bold;
  color: black;
}

.navbar_navigation_item:hover {
  text-decoration: 1px underline;
}

/* hero section */

.hero {
  z-index: 100;
  height: 82vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 100px;
  box-sizing: border-box;
  /* background: rgb(1, 179, 232);
  background: linear-gradient(
    90deg,
    rgba(1, 179, 232, 1) 24%,
    rgba(68, 211, 254, 1) 49%,
    rgba(105, 221, 255, 1) 80%,
    rgba(186, 241, 255, 1) 100%
  ); */
  background-color: rgb(244 255 244);
}

.hero_left {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.hero_left_main_text {
  font-size: 40px;
  font-weight: bold;
  text-align: start;
}

.hero_left_support_text {
  text-align: left;
  line-height: 25px;
}

.hero_clickToChat_button {
  border: 2px solid black;
  padding: 8px;
  border-radius: 6px;
  width: max-content;
  color: black;
  /* background: linear-gradient(
    90deg,
    rgba(19, 203, 12, 1) 24%,
    rgba(103, 212, 110, 1) 49%
  ); */
  background-color: #00e785;
  font-weight: bold;
}

.hero_right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  width: 100%;
}

.hero_right_img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  scale: 1.4;
}

/* chatbotUses */

.chatbotUses {
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 80px 100px;
  background-color: #f7f7f7;
}

.chatbotUses_section_heading {
  font-weight: bold;
  font-size: 34px;
  margin-bottom: 20px;
}

.chatbotCard_parent {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.chatbotCard {
  background-color: #fff;
  border-radius: 2px;
  padding: 20px;
  width: 16vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  height: max-content;
}

.chatbotCard_img {
  height: 80px;
  width: 80px;
  max-height: 80px;
  max-width: 80px;
  min-height: 80px;
  min-width: 80px;
  object-fit: contain;
  margin: 10px 0px 20px 0px;
}

.chatbotCard_heading {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
}

.chatbotCard_info {
  /* align-items: flex-start; */
  text-align: left;
}

/* -------------- industries ------------- */

.industries {
  z-index: 80;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 80px 100px;
  background-color: #f7f7f7;
}

.industries_section_heading {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 20px;
}

.industriesCard_parent {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: nowrap;
  overflow: scroll;
  padding: 10px 0px;
  /* -ms-overflow-style: none; IE and Edge */
  /* scrollbar-width: none; Firefox */
  overflow-y: auto;
}

.industriesCard_parent::-webkit-scrollbar {
  /* display: none;  */
  width: 30px;
}

.industriesCard {
  background-color: #fff;
  border-radius: 2px;
  padding: 20px;
  width: 130px;
  min-width: 130px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  /* height: max-content; */
  height: 210px;
}

.industriesCard_img {
  height: 100px;
  width: 100px;
  max-height: 100px;
  max-width: 100px;
  min-height: 100px;
  min-width: 100px;
  object-fit: contain;
  margin: 10px 0px 20px 0px;
  border-radius: 5px;
}

.industriesCard_heading {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
}

.industriesCard_info {
  /* align-items: flex-start; */
  text-align: left;
}

/* --------------- BRANDS ------------  */

.brands {
  padding: 50px 100px;
  background-color: #a7dcfe4b;
}

.brands_heading {
  font-size: 34px;
  font-weight: bold;
  padding-bottom: 10px;
}

.brands_sub_heading {
  padding-bottom: 40px;
}

.brands_items_parent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  /* animation: scroll 30s linear infinite; */
  width: 100%;
  flex-wrap: wrap;
}

.brands_item {
  border: 2px solid black;
  padding: 10px;
  width: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.brands_item_heading {
  font-weight: bold;
}

.brands_item_info {
  font-size: 12px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.brands_items_img {
  height: 60px;
  object-fit: contain;
}

/* --------------- CHATBOT PLATFORM ------------  */

.chatbot_platforms {
  padding: 50px 100px;
}

.chatbot_platforms_heading {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 30px;
}

.chatbot_platforms_items_parent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

/* .chatbot_platforms_item {
} */

.chatbot_platforms_items_img {
  height: 80px;
  width: 80px;
  object-fit: contain;
}

/* footer */

.footer {
  display: flex;
  align-items: start;
  justify-content: space-around;
  gap: 30px;
  z-index: 70;
  background: #f7f7f7;
  width: 100%;
  padding: 50px 100px;
  box-sizing: border-box;
}

.footer_item {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  width: 22vw;
}

.footer_item_heading {
  text-align: start;
  font-weight: bold;
}

.footer_item_info {
  text-align: start;
}

.footer_item_info_1 {
  font-size: 13px;
  text-align: start;
  margin-top: -5px;
}

.footer_item_company_img {
  max-height: 110px;
  max-width: 250px;
  object-fit: contain;
}

.privacy_copyright {
  padding: 50px 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  color: darkGrey;
  font-size: 1em;
}

.privacy_copyright>div:hover {
  color: black;
  cursor: pointer;
}

.privacy {
  padding: 50px 100px;
}

.privacy>div {
  text-align: left;
}

.policyDetailsSection {
  margin-top: 50px;
}

/* Select the first <td> in each <tr> */
table tr td:nth-child(1) {
  width: 35%;
  /* Custom width for the first column */
}

/* Select the second <td> in each <tr> */
table tr td:nth-child(2) {
  width: 50%;
  /* Custom width for the second column */
}

/* Select the third <td> in each <tr> */
table tr td:nth-child(3) {
  width: 15%;
  /* Custom width for the third column */
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid black;
  padding: 8px;
}

thead td {
  font-weight: bold;
}


/* ---------- TOS ------------ */

.tos {
  padding: 50px 100px;
}

.tos>div {
  text-align: left;
  margin-top: 3rem;
}


@media (max-width: 767px) {
  .navbar {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 6%;
    box-sizing: border-box;
    color: #000;
    background-color: rgb(244 255 244);
    position: sticky;
    top: 0px;
    height: auto;
  }

  .navbar_scrollable {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 6%;
    box-sizing: border-box;
    color: #000;
    background-color: rgb(244 255 244);
    height: auto;
  }

  .navbar_navigation_items {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .navbar_logo_img {
    height: 40px;
    width: 40px;
    object-fit: contain;
  }

  /* hero */
  .hero {
    width: 100vw !important;
    /* display: none; */
    padding: 30px 8%;
    padding-bottom: 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }

  .hero_left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }


  .hero_right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    width: 100%;
  }

  .hero_right_img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    scale: 1;
  }

  .chatbotUses {
    width: 100vw !important;
    padding: 30px 4%;
    box-sizing: border-box;
  }

  .chatbotCard {
    background-color: #fff;
    border-radius: 2px;
    padding: 10px;
    width: 40vw;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    height: max-content;
    box-sizing: border-box;
  }

  .brands {
    width: 100vw;
    padding: 40px 4%;
    box-sizing: border-box;
    background-color: #a7dcfe4b;
  }

  .industries {
    width: 100vw;
    padding: 40px 6%;
    box-sizing: border-box;
  }

  .industriesCard {
    background-color: #fff;
    border-radius: 2px;
    padding: 20px;
    width: 25vw;
    min-width: 25vw;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    /* height: max-content; */
    height: 210px;
  }

  .industriesCard_img {
    height: 80px;
    width: 80px;
    max-height: 80px;
    max-width: 80px;
    min-height: 80px;
    min-width: 80px;
    object-fit: contain;
    margin: 10px 0px 20px 0px;
    border-radius: 5px;
  }

  .chatbot_platforms {
    width: 100vw;
    padding: 40px 8%;
    box-sizing: border-box;
    text-align: center;
  }

  .chatbot_platforms_items_parent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }

  .chatbot_platforms_items_img {
    height: 40px;
    width: 40px;
    object-fit: contain;
  }

  .footer {
    width: 100vw;
    padding: 60px 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* display: none; */
  }


  .footer_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .footer_item_info {
    text-align: center;
  }

  .privacy_copyright {
    padding: 60px 4%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    color: darkGrey;
    font-size: 1em;
  }

  .privacy_copyright>div:hover {
    color: black;
    cursor: pointer;
  }

  .privacy {
    padding: 60px 4%;

  }

  .privacy>div {
    text-align: left;
  }

  .tos {
    padding: 60px 4%;
  }

  .tos>div {
    text-align: left;
    margin-top: 3rem;
  }
}